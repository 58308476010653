import {
  Box,
  Button,
  Card,
  Loader,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  WixDesignSystemProvider,
  Image,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import React, { useEffect } from "react";
import HeroImage from "./hero.png";
import { ReactTagManager } from "react-gtm-ts";
import * as Icons from "@wix/wix-ui-icons-common";
import { useTranslation } from "react-i18next";
import i18n from './i18n';
import "./App.css";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const CONTENT = [
  "Digital Clock, Analog Clock, and much more",
  "Customized pro widgets support",
  "Priority support",
];

const INTERVAL = 3000;

function App() {
  
  const { t } = useTranslation();

  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [index, setIndex] = React.useState<number>(0);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  const isFree = instanceData?.instance?.isFree !== false;
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, []);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  const BASE_URL = `https://certifiedcode.wixsite.com/clock/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }
  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=b06f2ef6-a9af-43b9-ab7c-2749448a41e8&redirectUrl=https://certifiedcode.wixsite.com/clock/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }
  const message = t('upgradeMessage', { content: t(`content.${index}`) });
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>{message}</Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(
              `https://www.wix.com/apps/upgrade/${"b06f2ef6-a9af-43b9-ab7c-2749448a41e8"}?appInstanceId=${
                (instanceData as any)?.instance?.instanceId
              }              `
            );
          }}
        >
          {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          className="close-button"
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title={t('clock-title')}
                content={t('clock-description')}
                actions={
                  <Box direction="horizontal" gap="SP1">
                    <Button
                      skin="premium"
                      as="a"
                      href={`https://www.wix.com/apps/upgrade/${"b06f2ef6-a9af-43b9-ab7c-2749448a41e8"}?appInstanceId=${
                        (instanceData as any)?.instance?.instanceId
                      }`}
                      target="_blank"
                    >
                      {t('clock-unlock-button')}
                    </Button>
                    <Button
                      prefixIcon={<Icons.Reviews />}
                      skin="inverted"
                      as="a"
                      href="https://bridget.reviews.certifiedcode.us/b06f2ef6-a9af-43b9-ab7c-2749448a41e8"
                      target="_blank"
                    >
                      {t('clock-review-buttn')}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
          image={<Image borderRadius={0} src={HeroImage} />}
        />
      </Card>
    </WixDesignSystemProvider>
  );
}

export default App;
